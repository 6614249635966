import React, { Component } from "react"
import { headerWithUs } from '../../../../images';
import { BackgroundImages } from "../../../../shared";
import { OutboundLink } from 'gatsby-plugin-gtag'

class HeaderWorkWithUs extends Component {
  render() {
    return (
      <div className="header-with-us">
        <BackgroundImages style={{
          backgroundImage: `url(${headerWithUs})`,
          zIndex: -1,
        }}>
          <div className="box-header">
            <div className="bg-header" />
            <div className="box-text-header-with-us">
              <h1 className="text-header-with-us">
                Work With Us
              </h1>
              <p className="description-header-with-us">
                Making things easy for you. We respond you via email, skype and our own project management system with real-time tracking. Our process is designed to help you save time and assist you in catching progress real time via our online management system.
                <br/>
                We manage project source code and deployment with version system to keep your product safe and prevent hassle when making any updates to live production.
              </p>
              <OutboundLink href="/contact-us" className="read-more">Contact Us</OutboundLink>
            </div>
          </div>
        </BackgroundImages>
      </div>
    )
  }
}

export default HeaderWorkWithUs;
