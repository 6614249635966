import React from "react"

import Layout from "../components/layout"
import HeaderWorkWithUs from "../components/screens/WorkWithUs/HeaderWorkWithUs"
import BoxWithUs from "../components/screens/WorkWithUs/BoxWithUs"
import { Helmet } from "react-helmet"

const WithUsPage = () => (
  <Layout pageInfo={{ pageName: "Work With Us" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Work With Us</title>
        <meta name="description" content="OpenTechiz 's work with us page" />
    </Helmet>
    <div className="wrapper-work-with-us">
      <HeaderWorkWithUs />
      <div className="wrapper-box-service">
        <BoxWithUs
          bold="A typical process includes"
          image={require("../images/jpg/sub-services-9.jpg")}
        >
          <p className="normal-text">
            - Requirement grab and business understanding<br/>
            - Visual layout discussion and graphic design<br/>
            - Development and internal quality control<br/>
            - Review and acceptance tests<br/>
            - Content update and deploy<br/>
          </p>
        </BoxWithUs>
        <BoxWithUs
          left
          image={require("../images/jpg/sub-services-8.jpg")}
          normal="We also apply agile methodology to help you to apply changes on requirement easily"
        />
      </div>
    </div>
  </Layout>
)

export default WithUsPage
