import React, { Component } from "react"
import {
  Image
} from "react-bootstrap"

class BoxWithUs extends Component {
  constructor(props) {
    super(props);
    this.renderWithUs = this.renderWithUs.bind(this);
  }

  renderWithUs() {
    let boxItem;
    if (this.props.left) {
      boxItem = (
        <div className="wrapper-box-with-us">
          <div className="left-box-with-us">
            <p className="normal-text">{this.props.normal}</p>
          </div>
          <div className="right-box-with-us">
            <Image
              alt="900x500"
              className="image-box-item-with-us lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
              data-src={this.props.image}
            />
          </div>
        </div>
      )
    } else {
      boxItem = (
        <div className="wrapper-box-with-us">
          <div className="left-box-with-us left">
            <p className="bold-text">{this.props.bold}</p>
            {this.props.children}
          </div>
          <div className="right-box-with-us right">
            <Image
              alt="900x500"
              className="image-box-item-with-us lazyload"
              src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII=" 
              data-src={this.props.image}
            />
          </div>
        </div>
      )
    }

    return (
      <div>
        {boxItem}
      </div>
    )
  }

  render() {
    return (
      <div className="box-with-us">
        {this.renderWithUs()}
      </div>
    )
  }
}

export default BoxWithUs;
